import React from "react";
import { MeetingDepartmentType } from "@mightybot/core";
import { GenericFilterContent } from "./GenericFilterContent";

type TypeFilterContentProps = {
	selectedTags: MeetingDepartmentType[];
	handleTagSelect: (tag: MeetingDepartmentType) => void;
};

const DEPARTMENT_TYPE_OPTIONS: MeetingDepartmentType[] = [
	"Company Wide",
	"Engineering",
	"Marketing",
	"Sales",
	"Product Design",
	"Product Management",
	"Finance & Legal",
	"Solution Engineering",
	"Customer Onboarding",
	"Customer Support",
	"Human Resources",
	"IT and Technical",
	"Executive or Board Meetings",
	"Cross Departmental",
	"Other",
];

const TAG_OPTIONS: MeetingDepartmentType[] = [...DEPARTMENT_TYPE_OPTIONS];

export const TagFilterContent = ({
	selectedTags,
	handleTagSelect,
}: TypeFilterContentProps) => {
	const handleSelectAll = () => {
		TAG_OPTIONS.forEach((tag) => {
			if (!selectedTags.includes(tag)) {
				handleTagSelect(tag);
			}
		});
	};

	const handleReset = () => {
		selectedTags.forEach((tag) => {
			handleTagSelect(tag);
		});
	};

	return (
		<GenericFilterContent
			title="Select Tags"
			options={TAG_OPTIONS}
			selectedOptions={selectedTags}
			handleOptionSelect={handleTagSelect}
			handleSelectAll={handleSelectAll}
			handleReset={handleReset}
		/>
	);
};

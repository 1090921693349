import React, { useState } from "react";
import {
	Flex,
	Text,
	AvatarWrapper,
	Input,
	Checkbox,
	Skeleton,
} from "@mightybot/web-ui";
import { useRelatedPeople, User, PeopleFilterValue } from "@mightybot/core";
import { PeopleFilterContainer, PersonRow } from "./styled";

interface PeopleFilterContentProps {
	selectedPeople: PeopleFilterValue;
	handlePersonSelect: (person: Partial<User>) => void;
}

const LoadingState = () => (
	<PeopleFilterContainer direction="column" style={{ padding: "10px" }}>
		<Skeleton height="36px" width="100%" style={{ marginBottom: "10px" }} />
		{[1, 2, 3, 4].map((i) => (
			<Flex key={i} gap="4px" align="center" style={{ padding: "4px" }}>
				<Skeleton width="16px" height="16px" />
				<Skeleton width="16px" height="16px" style={{ borderRadius: "50%" }} />
				<Skeleton height="16px" width="120px" />
			</Flex>
		))}
	</PeopleFilterContainer>
);

const PeopleFilterContent: React.FC<PeopleFilterContentProps> = ({
	selectedPeople,
	handlePersonSelect,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const { data: people = [], isLoading } = useRelatedPeople();

	const filteredPeople = people
		.filter((person: Partial<User>) =>
			person?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
		)
		.sort((a, b) => (a.name || "").localeCompare(b.name || ""));

	const handleSelectAll = () => {
		if (selectedPeople.length === filteredPeople.length) {
			// Deselect all filtered people
			const newSelectedPeople = selectedPeople.filter(
				(selected) =>
					!filteredPeople.some((p: Partial<User>) => p.id === selected),
			);
			filteredPeople.forEach((person: Partial<User>) =>
				handlePersonSelect(person),
			);
		} else {
			// Select all filtered people that aren't already selected
			filteredPeople.forEach((person: Partial<User>) => {
				if (!selectedPeople.some((selected) => selected === person.id)) {
					handlePersonSelect(person);
				}
			});
		}
	};

	if (isLoading) {
		return <LoadingState />;
	}

	const isAllSelected =
		filteredPeople.length > 0 &&
		filteredPeople.every((person: Partial<User>) =>
			selectedPeople.includes(person.id!),
		);

	return (
		<PeopleFilterContainer direction="column">
			<Input
				placeholder="eg. John or @john"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				style={{
					background: "var(--mb-gray-4)",
					border: "none",
					height: "32px",
					maxWidth: 260,
					borderRadius: "3px",
					boxShadow: "none",
					margin: "10px",
				}}
			/>

			<Flex direction="column" gap="6px">
				{filteredPeople.length > 0 && (
					<PersonRow align="center" onClick={handleSelectAll}>
						<Checkbox
							checked={isAllSelected}
							onCheckedChange={handleSelectAll}
						/>
						<Text weight="medium">Select All</Text>
					</PersonRow>
				)}

				{filteredPeople.map((person: Partial<User>) => (
					<PersonRow
						key={person.id}
						align="center"
						onClick={() => handlePersonSelect(person)}
					>
						<Checkbox
							checked={selectedPeople.includes(person.id!)}
							// onCheckedChange={() => handlePersonSelect(person)}
						/>
						<AvatarWrapper
							src={person.profile_pic || ""}
							alt={person.name || ""}
							size="1"
							radius="full"
						/>
						<Flex direction="column">
							<Text>{person.name}</Text>
							<Text size="1" style={{ color: "var(--mb-gray-7)" }}>
								{person.email}
							</Text>
						</Flex>
					</PersonRow>
				))}

				{filteredPeople.length === 0 && (
					<Text style={{ color: "var(--mb-gray-9)", padding: "10px" }}>
						No people found
					</Text>
				)}
			</Flex>
		</PeopleFilterContainer>
	);
};

export default PeopleFilterContent;

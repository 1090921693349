import React, { useState, useEffect } from "react";
import { Flex, Text, Icon, Skeleton } from "@mightybot/web-ui";
import { SearchFilters as SearchFiltersType, Apps } from "@mightybot/core";
import { MBLogoGray, useIntegrationLogos } from "@mightybot/core-ui";
import { AppFilterPill, AppFilterPreviewContainer } from "./styled";
import { useAgentSearch } from "@mightybot/core";

// Define valid apps array based on the Apps type
const VALID_APPS: Apps[] = [
	"Google Calendar",
	"Gmail",
	"Google Docs",
	"Google Sheets",
	"PDF",
	"Slack",
	"Linear",
	"Meetings",
	"Salesforce",
	"HubSpot",
];

interface AppFilterPreviewProps {
	onFilterChange?: (filters: SearchFiltersType) => void;
}

// Add this new component for animated count
const AnimatedCount: React.FC<{ count: number; isLoading: boolean }> = ({
	count,
	isLoading,
}) => {
	const [displayCount, setDisplayCount] = useState(count);

	useEffect(() => {
		if (!isLoading) {
			setDisplayCount(count);
		}
	}, [count, isLoading]);

	if (isLoading)
		return (
			<Text
				style={{
					color: "#889096",
					fontSize: 13,
					opacity: 0.5,
					transition: "opacity 0.2s ease-in-out",
				}}
			>
				...
			</Text>
		);

	return (
		<Text
			style={{
				color: "#889096",
				fontSize: 13,
				transition: "all 0.3s ease-out",
			}}
		>
			{displayCount}
		</Text>
	);
};

const AppFilterPreview: React.FC<AppFilterPreviewProps> = ({
	onFilterChange,
}) => {
	const [selectedApps, setSelectedApps] = useState<string[] | null>(null);
	const { getIntegrationLogoRegex } = useIntegrationLogos();
	const { searchCounts, isLoading } = useAgentSearch();

	// Sort apps based on counts
	const sortedApps = React.useMemo(() => {
		return [...VALID_APPS].sort((a, b) => {
			const countA = searchCounts?.counts?.[a as Apps] ?? 0;
			const countB = searchCounts?.counts?.[b as Apps] ?? 0;
			return countB - countA; // Sort in descending order
		});
	}, [searchCounts]);

	const handleAppSelect = (app: string) => {
		let newSelectedApps: string[];

		if (app === "all") {
			newSelectedApps =
				selectedApps?.length === VALID_APPS.length ? [] : [...VALID_APPS];
		} else {
			newSelectedApps = selectedApps?.includes(app)
				? selectedApps?.filter((a) => a !== app)
				: [...(selectedApps ?? []), app];
		}

		setSelectedApps(newSelectedApps);
		onFilterChange?.({ appFilter: newSelectedApps });
	};

	const isAllSelected =
		!selectedApps || selectedApps?.length === VALID_APPS.length;
	const totalCount = searchCounts?.total ?? 0;

	return (
		<AppFilterPreviewContainer direction="column" gap="8px">
			<Flex
				gap="6px"
				wrap="wrap"
				style={{
					width: "100%",
					justifyContent: "center",
				}}
			>
				<AppFilterPill
					onClick={() => handleAppSelect("all")}
					selected={isAllSelected}
				>
					<Icon.Check size={16} />
					<Text style={{ fontSize: 13 }}>All Apps</Text>
					<AnimatedCount count={totalCount} isLoading={isLoading} />
				</AppFilterPill>
				{sortedApps.map((app) => (
					<AppFilterPill
						key={app}
						onClick={() => handleAppSelect(app)}
						selected={selectedApps?.includes(app) ?? false}
					>
						<img
							src={
								getIntegrationLogoRegex(app.toLowerCase().replace(" ", "_")) ??
								MBLogoGray
							}
							alt={app}
							width={16}
							height={16}
						/>
						{app}
						<AnimatedCount
							count={searchCounts?.counts?.[app as Apps] ?? 0}
							isLoading={isLoading}
						/>
					</AppFilterPill>
				))}
			</Flex>
		</AppFilterPreviewContainer>
	);
};

export default AppFilterPreview;

import React from "react";
import { Checkbox, Flex, Icon, Text } from "@mightybot/web-ui";
import { FilterContainer, FilterOption } from "./styled";

type GenericFilterContentProps<T> = {
	title: string;
	options: T[];
	selectedOptions: T[];
	handleOptionSelect: (option: T) => void;
	handleSelectAll: () => void;
	handleReset: () => void;
	getOptionLabel?: (option: T) => string;
};

export const GenericFilterContent = <T extends string>({
	title,
	options,
	selectedOptions,
	handleOptionSelect,
	handleSelectAll,
	handleReset,
	getOptionLabel = (option) => option,
}: GenericFilterContentProps<T>) => {
	const isAllSelected = options.length === selectedOptions.length;
	const hasSelections = selectedOptions.length > 0;

	return (
		<FilterContainer direction="column" gap="8px" p="12px">
			<Text weight="medium">{title}</Text>
			<Flex direction="column" gap="6px" p="4px">
				<Flex justify="between" align="center">
					<FilterOption align="center" onClick={handleSelectAll}>
						<Checkbox checked={isAllSelected} />
						<Text weight="medium">Select All</Text>
					</FilterOption>
					{hasSelections && (
						<FilterOption align="center" onClick={handleReset}>
							<Icon.X />
							<Text weight="medium" style={{ color: "var(--mb-red-9)" }}>
								Reset
							</Text>
						</FilterOption>
					)}
				</Flex>

				{options.map((option) => (
					<FilterOption
						key={option}
						align="center"
						onClick={() => handleOptionSelect(option)}
					>
						<Checkbox checked={selectedOptions.includes(option)} />
						<Text size="2">{getOptionLabel(option)}</Text>
					</FilterOption>
				))}
			</Flex>
		</FilterContainer>
	);
};

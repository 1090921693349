import React from "react";
import { MeetingCategoryType } from "@mightybot/core";
import { GenericFilterContent } from "./GenericFilterContent";

type CategoryFilterContentProps = {
	selectedCategories: MeetingCategoryType[];
	handleCategorySelect: (category: MeetingCategoryType) => void;
};

const CATEGORY_OPTIONS: MeetingCategoryType[] = [
	"Team Meetings",
	"Project Meetings",
	"Leadership and Management Meetings",
	"Training and Development Meetings",
	"Special Purpose Meetings",
	"Client Meetings",
	"Partner Meetings",
	"Industry Meetings",
	"Negotiation Meetings",
	"Public Relations and Community Meetings",
	"Interview Meetings",
];

export const CategoryFilterContent = ({
	selectedCategories,
	handleCategorySelect,
}: CategoryFilterContentProps) => {
	const handleSelectAll = () => {
		CATEGORY_OPTIONS.forEach((category) => {
			if (!selectedCategories.includes(category)) {
				handleCategorySelect(category);
			}
		});
	};

	const handleReset = () => {
		selectedCategories.forEach((category) => {
			handleCategorySelect(category);
		});
	};

	return (
		<GenericFilterContent
			title="Select Category"
			options={CATEGORY_OPTIONS}
			selectedOptions={selectedCategories}
			handleOptionSelect={handleCategorySelect}
			handleSelectAll={handleSelectAll}
			handleReset={handleReset}
		/>
	);
};

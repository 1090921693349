import { Flex } from "@mightybot/web-ui";
import styled from "styled-components";

export const AppFilterPill = styled(Flex)<{
	selected: boolean;
}>`
	gap: 6px;
	border-radius: var(--radius-pill);
	padding: 0px 12px;
	height: 28px;
	border: 1px solid var(--mb-gray-6);
	align-items: center;
	cursor: pointer;
	background-color: ${(props) =>
		props.selected ? "var(--mb-blue-2)" : "transparent"};
	border-color: ${(props) =>
		props.selected ? "var(--mb-blue-6)" : "var(--mb-gray-6)"};

	&:hover {
		border-color: var(--mb-blue-6);

		svg {
			color: var(--mb-red-7);
		}
	}

	svg {
		color: var(--mb-gray-8);
		transition: color 0.2s ease;
	}
`;

export const AppFilterPreviewContainer = styled(Flex)`
	padding: 16px 0;
	justify-content: center;

	// Create rows with different widths for pyramid effect
	& > div {
		justify-content: center;
	}

	& > div:nth-child(1) {
		width: 100%;
	}
	& > div:nth-child(2) {
		width: 85%;
	}
`;

export const PeopleFilterContainer = styled(Flex)`
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
`;

export const PersonRow = styled(Flex)`
	gap: 8px;
	cursor: pointer;
	height: 32px;
	transition: background-color 0.2s ease;
	padding: 10px;

	&:hover {
		background: var(--mb-gray-2);
	}
`;

export const TimestampFilterOption = styled(Flex)<{ selected: boolean }>`
	flex-direction: column;
	padding: 8px 10px;
	transition: background-color 0.2s ease;
	background-color: ${(props) =>
		props.selected ? "var(--mb-gray-2)" : "transparent"};

	&:hover {
		background: var(--mb-gray-2);
	}
`;

export const FilterContainer = styled(Flex)`
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
`;

export const FilterOption = styled(Flex)`
	gap: 8px;
	cursor: pointer;
	height: 32px;
	transition: background-color 0.2s ease;
	padding: 4px;

	&:hover {
		background: var(--mb-gray-2);
	}
`;

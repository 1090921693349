import React, { useState, useMemo, useEffect } from "react";
import {
	FilterContainer,
	FilterItem,
	SelectedFiltersRow,
	OverlappingImagesContainer,
	OverlappingImage,
	StyledButton,
	FilterActionsContainer,
} from "./styled";
import { IconProps as PhosphorIconProps } from "@phosphor-icons/react";

import { Text, Icon, Flex, AvatarWrapper, Button } from "@mightybot/web-ui";
import FilterPopover from "../FilterPopover";
import { useIntegrationLogos, MBLogoGray } from "@mightybot/core-ui";
import {
	NLSearchResult,
	TimestampFilterValue,
	AppFilterValue,
	PeopleFilterValue,
	SearchFilters as SearchFiltersType,
	User,
	useRelatedPeople,
	useSearch,
	useSearchSelection,
} from "@mightybot/core";
import TimestampFilterContent from "../FilterPopover/FilterContent/TimestampFilterContent";
import { format } from "date-fns";
import AppFilterContent, {
	VALID_APPS,
} from "../FilterPopover/FilterContent/AppFilterContent";
import PeopleFilterContent from "../FilterPopover/FilterContent/PeopleFilterContent";
import { UUID } from "crypto";
import { useSearchParams } from "react-router-dom";

interface AppResultCount {
	app: string;
	count: number;
}
type FilterType = "apps" | "timestamp" | "people";

const FILTER_BY_OPTIONS: {
	label: string;
	value: FilterType;
	icon: React.ComponentType<
		PhosphorIconProps & { style?: React.CSSProperties }
	>;
}[] = [
	{ label: "Apps", value: "apps", icon: Icon.CirclesFour },
	{ label: "Updated", value: "timestamp", icon: Icon.CalendarBlank },
	{ label: "People", value: "people", icon: Icon.AddressBook },
];

interface SearchFiltersProps {
	results: NLSearchResult[];
	onFilterChange: (filters: SearchFiltersType, onlySearch?: boolean) => void;
	isFirstRender: React.MutableRefObject<boolean>;
	isDialog: boolean;
	onToggleShowSelected: () => void;
	showSelectedOnly: boolean;
}

const SearchFilters: React.FC<SearchFiltersProps> = ({
	results,
	onFilterChange,
	isFirstRender,
	isDialog,
	onToggleShowSelected,
	showSelectedOnly,
}) => {
	const [searchParams] = useSearchParams();
	const activeSearchId = searchParams.get("searchId") as UUID;

	const [openFilter, setOpenFilter] = useState<string | null>(null);
	const [selectedApps, setSelectedApps] = useState<AppFilterValue | null>(null);
	const [selectedPeople, setSelectedPeople] = useState<PeopleFilterValue>([]);
	const [timestampFilter, setTimestampFilter] = useState<TimestampFilterValue>({
		type: "any_time",
	});

	const { activeSearch } = useSearch(activeSearchId);
	const { selectedResults } = useSearchSelection();
	const { getIntegrationLogoRegex } = useIntegrationLogos();
	const { data: people = [] } = useRelatedPeople();

	// Add state to track previous values
	const [previousSelectedApps, setPreviousSelectedApps] =
		useState<AppFilterValue | null>(null);
	const [previousSelectedPeople, setPreviousSelectedPeople] =
		useState<PeopleFilterValue>([]);

	// Add effect to apply filters when activeSearch changes
	useEffect(() => {
		const loadFilters = async () => {
			isFirstRender.current = false;
			const savedFilters = activeSearch?.filters;

			setSelectedApps(savedFilters?.appFilter ?? null);

			const timestampFilter = {
				type: savedFilters?.timestampFilter?.type ?? "any_time",
				...savedFilters?.timestampFilter,
			};
			// convert to date objects
			timestampFilter.startDate = savedFilters?.timestampFilter?.startDate
				? new Date(savedFilters.timestampFilter.startDate)
				: undefined;
			timestampFilter.endDate = savedFilters?.timestampFilter?.endDate
				? new Date(savedFilters.timestampFilter.endDate)
				: undefined;
			setTimestampFilter(timestampFilter);

			setSelectedPeople(savedFilters?.peopleFilter ?? []);

			onFilterChange(
				{
					appFilter: savedFilters?.appFilter,
					timestampFilter: timestampFilter,
					peopleFilter: savedFilters?.peopleFilter,
				},
				true,
			);
		};

		if (activeSearch?.id && isFirstRender.current) {
			loadFilters();
		}
	}, [activeSearch?.id]);

	// Calculate results count per app
	const appResultCounts = useMemo((): AppResultCount[] => {
		const counts: Record<string, number> = results.reduce(
			(acc: Record<string, number>, result) => {
				const { app } = result as NLSearchResult;
				if (!app) return acc;
				return { ...acc, [app]: (acc[app] || 0) + 1 };
			},
			{},
		);

		return Object.entries(counts).map(([app, count]) => ({
			app,
			count,
		}));
	}, [results]);

	const handleAppSelect = (app: string) => {
		setSelectedApps((prev) => {
			// If "all" is clicked, select all valid apps that have results
			if (app === "all") {
				return !prev || VALID_APPS.length === (prev?.length ?? 0)
					? []
					: VALID_APPS;
			}

			// If the app is already selected, remove it
			if (prev?.includes(app)) {
				return prev.filter((a) => a !== app);
			}

			// Add the app to selection
			return [...(prev ?? []), app];
		});
	};

	const handleApply = async (filterType: FilterType) => {
		if (filterType === "apps") {
			onFilterChange({
				appFilter: selectedApps ?? [],
			});
		} else if (filterType === "timestamp") {
			onFilterChange({ timestampFilter });
		} else if (filterType === "people") {
			onFilterChange({ peopleFilter: selectedPeople });
		}
		setOpenFilter(null);
	};

	// Modify handleCancel to restore previous values
	const handleCancel = (filterType: FilterType) => {
		if (filterType === "apps") {
			setSelectedApps(previousSelectedApps);
		} else if (filterType === "people") {
			setSelectedPeople(previousSelectedPeople);
		}
		setOpenFilter(null);
	};

	// Store previous values when opening filter
	const handleOpenFilter = (filterType: FilterType | null) => {
		if (!filterType) {
			setOpenFilter(null);
			return;
		}

		if (filterType === "apps") {
			setPreviousSelectedApps(selectedApps);
		} else if (filterType === "people") {
			setPreviousSelectedPeople(selectedPeople);
		}
		setOpenFilter(filterType);
	};

	const formatTimestampFilterLabel = (filter: TimestampFilterValue): string => {
		switch (filter.type) {
			case "preset":
				return `${format(filter.startDate!, "MMM d, yyyy")}`;
			case "custom_until_now":
				const date = filter.endDate || filter.startDate;
				return date ? `${format(date!, "MMM d, yyyy")}` : ``;
			case "custom_range":
				if (!filter.startDate || !filter.endDate) {
					return "";
				}
				return `${format(filter.startDate!, "MMM d, yyyy")} - ${format(
					filter.endDate!,
					"MMM d, yyyy",
				)}`;
			default:
				return "Any time";
		}
	};

	const renderFilterContent = (filterType: string) => {
		switch (filterType) {
			case "apps":
				return (
					<AppFilterContent
						appResultCounts={appResultCounts}
						selectedApps={selectedApps ?? null}
						handleAppSelect={handleAppSelect}
						results={results}
					/>
				);
			case "timestamp":
				return (
					<TimestampFilterContent
						value={timestampFilter}
						onChange={handleTimestampFilterChange}
					/>
				);
			case "people":
				return (
					<PeopleFilterContent
						selectedPeople={selectedPeople}
						handlePersonSelect={handlePersonSelect}
					/>
				);
			default:
				return null;
		}
	};

	const resetFilters = async (filterType: FilterType) => {
		if (filterType === "apps") {
			setSelectedApps([]);
			onFilterChange({ appFilter: [] });
		}
		if (filterType === "timestamp") {
			setTimestampFilter({ type: "any_time" });

			onFilterChange({ timestampFilter: { type: "any_time" } });
		}
		if (filterType === "people") {
			setSelectedPeople([]);
			onFilterChange({ peopleFilter: [] });
		}
	};

	const handleTimestampFilterChange = async (filter: TimestampFilterValue) => {
		let triggerExecuteSearch = true;
		if (
			["custom_until_now", "custom_range"].includes(filter.type) &&
			!filter.startDate &&
			!filter.endDate
		) {
			triggerExecuteSearch = false;
		}

		setTimestampFilter(filter);

		if (triggerExecuteSearch) {
			onFilterChange({ timestampFilter: filter });
		}

		if (!triggerExecuteSearch) {
			setOpenFilter("timestamp");
		} else {
			setOpenFilter(null);
		}
	};

	const handlePersonSelect = (person: Partial<User>) => {
		if (!person.id) return;

		setSelectedPeople((prev: PeopleFilterValue) => {
			const id = person.id as UUID;
			return prev.includes(id)
				? prev.filter((pid) => pid !== id)
				: [...prev, id];
		});
	};

	const renderSelectedFilters = (
		filterType: FilterType,
		selectedFilters: SearchFiltersType[keyof SearchFiltersType],
	) => {
		return (
			<SelectedFiltersRow>
				<Flex gap="8px" align="center">
					<Text
						weight="medium"
						style={{ fontSize: "13px", color: "var(--mb-gray-9)" }}
					>
						{["apps", "people"].includes(filterType) && "in any of"}
						{filterType === "timestamp" &&
						((selectedFilters as TimestampFilterValue).startDate ||
							(selectedFilters as TimestampFilterValue).endDate)
							? timestampFilter.type !== "custom_range"
								? "after"
								: "between"
							: ""}
					</Text>
					{filterType !== "timestamp" && (
						<Flex
							gap="4px"
							align="center"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setOpenFilter(filterType);
							}}
						>
							<OverlappingImagesContainer>
								{filterType === "people"
									? people
											.filter((person) => selectedPeople.includes(person.id!))
											.slice(0, 3)
											.map((person, index) => (
												<OverlappingImage
													key={person.id}
													style={{
														zIndex: selectedPeople.length - index,
													}}
												>
													<AvatarWrapper
														src={person.profile_pic ?? ""}
														alt={person.name ?? ""}
														radius="full"
														styles={{ width: 14, height: 14, fontSize: 8 }}
													/>
												</OverlappingImage>
											))
									: (selectedFilters as string[])
											.slice(0, 3)
											.map((f, index) => (
												<OverlappingImage
													key={f}
													style={{
														zIndex: (selectedApps?.length ?? 0) - index,
													}}
												>
													<img
														src={
															getIntegrationLogoRegex(
																f.toLowerCase().replace(" ", "_"),
															) ?? MBLogoGray
														}
														alt={f.toLowerCase().replace(" ", "_")}
														width={14}
														height={14}
													/>
												</OverlappingImage>
											))}
							</OverlappingImagesContainer>
							{Array.isArray(selectedFilters) && selectedFilters.length > 3 && (
								<Text style={{ fontSize: "12px", color: "var(--mb-gray-9)" }}>
									+{selectedFilters.length - 3}
								</Text>
							)}
						</Flex>
					)}
					{filterType === "timestamp" && (
						<Text style={{ fontSize: "13px", color: "var(--mb-gray-9)" }}>
							{formatTimestampFilterLabel(timestampFilter)}
						</Text>
					)}
					<Icon.X
						size={16}
						style={{ cursor: "pointer", color: "var(--mb-gray-9)" }}
						onClick={(e: React.MouseEvent) => {
							e.stopPropagation();
							resetFilters(filterType);
						}}
					/>
				</Flex>
			</SelectedFiltersRow>
		);
	};

	return (
		<FilterContainer>
			<FilterActionsContainer>
				<Icon.SlidersHorizontal
					size={16}
					color="var(--mb-gray-9)"
					weight="bold"
				/>
				<Text
					weight="medium"
					style={{ color: "var(--mb-gray-9)", fontSize: "13px" }}
				>
					Filter by
				</Text>
				{FILTER_BY_OPTIONS.map((option) => {
					let selectedFilters: SearchFiltersType[keyof SearchFiltersType] = [];
					switch (option.value) {
						case "apps":
							selectedFilters = selectedApps ?? [];
							break;
						case "people":
							selectedFilters = selectedPeople;
							break;
						case "timestamp":
							selectedFilters = timestampFilter;
							break;
					}
					const isSelected =
						(Array.isArray(selectedFilters) && selectedFilters.length > 0) ||
						(option.value === "timestamp" &&
							(selectedFilters as TimestampFilterValue).type !== "any_time");
					let label = option.label;
					if (option.value === "timestamp") {
						label = formatTimestampFilterLabel(
							selectedFilters as TimestampFilterValue,
						);
					}
					return (
						<Flex direction="row" gap="8px" key={option.value}>
							<FilterPopover
								trigger={
									<FilterItem>
										<option.icon size={16} style={{ flexShrink: 0 }} />

										<Text weight="medium" style={{ flexShrink: 0 }}>
											{option.label}
										</Text>
										{!isSelected && <Icon.CaretDown size={16} />}
										{isSelected &&
											renderSelectedFilters(option.value, selectedFilters)}
									</FilterItem>
								}
								isOpen={openFilter === option.value}
								onOpenChange={(open) =>
									handleOpenFilter(open ? option.value : null)
								}
								onApply={() => handleApply(option.value)}
								onCancel={() => handleCancel(option.value)}
								showActionButtons={option.value !== "timestamp"}
							>
								{renderFilterContent(option.value)}
							</FilterPopover>
						</Flex>
					);
				})}
			</FilterActionsContainer>
			{isDialog && selectedResults.length > 0 && (
				<StyledButton
					size="1"
					variant="ghost"
					onClick={onToggleShowSelected}
					style={{
						backgroundColor: showSelectedOnly
							? "var(--mb-blue-1)"
							: "transparent",
						border: "1px solid var(--mb-blue-8)",
					}}
				>
					<Icon.At />
					{selectedResults.length}{" "}
					{selectedResults.length === 1 ? "file" : "files"} selected
				</StyledButton>
			)}
		</FilterContainer>
	);
};

export default SearchFilters;

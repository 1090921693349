import { Popover, Flex } from "@mightybot/web-ui";
import { styled } from "styled-components";

export const PopoverContent = styled(Popover.Content)`
	display: flex;
	flex-direction: column;
	min-width: 250px;
	padding: 0px;
	gap: 16px;
	max-width: 600px;
`;

export const FilterContent = styled(Flex)`
	flex-direction: column;
	gap: 12px;
`;

export const ActionButtons = styled(Flex)`
	justify-content: flex-end;
	gap: 8px;
	background: rgba(237, 243, 255, 0.5);
	padding: 10px 16px;
	justify-content: flex-end;
`;

import React, { useEffect, useState, useRef } from "react";
import { UUID } from "crypto";

import {
	NLSearchResult,
	useExecuteSearch,
	useSearch,
	Search as SearchType,
	SearchFilters as SearchFiltersType,
	useRelatedPeople,
	useSearchSelection,
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import {
	SearchContainer,
	SearchHeader,
	SearchInnerContainer,
	StickyContainer,
} from "./styled";
import SearchSidebar from "./SearchSidebar";
import SearchBar from "./SearchBar";
import SearchFilters from "./SearchFilters";
import SearchResults from "./SearchResults";
import SearchResultsSummary from "./SearchResultsSummary";
import { useLocation, useSearchParams } from "react-router-dom";
import AppFilterPreview from "../FilterPopover/FilterContent/AppFilterPreview";

interface SearchProps {
	isDialog?: boolean;
}

const getAnalyticsFilters = (filters: SearchFiltersType) => ({
	appFilter: filters.appFilter?.map((f) => f.toString()) || [],
	timestampFilter: filters.timestampFilter?.type || "any_time",
	peopleFilter: filters.peopleFilter || [],
});

const Search: React.FC<SearchProps> = ({ isDialog = false }) => {
	const isFirstRender = useRef(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const { pathname } = useLocation();
	const { data: people = [] } = useRelatedPeople();

	const activeSearchId = searchParams.get("searchId") as UUID;

	const {
		performNLSearch,
		isLoading: isSearchLoading,
		isError,
		error,
	} = useExecuteSearch();
	const {
		updateSearch: updateSearchQuery,
		activeSearch,
		refetchActiveSearch,
		createSearch: createSearchQuery,
		refetchUserSearches,
		clearActiveSearch,
	} = useSearch(activeSearchId);
	const { selectedResults, setSelectionMode, clearSelection } =
		useSearchSelection(isDialog);
	const [hasResults, setHasResults] = useState(false);
	const [searchResults, setSearchResults] = useState<NLSearchResult[]>([]);
	const [activeFilters, setActiveFilters] = useState<SearchFiltersType>({
		timestampFilter: {
			type: "any_time",
		},
	});

	const [resultsScrolled, setResultsScrolled] = useState(false);
	const [showSelectedOnly, setShowSelectedOnly] = useState<boolean>(
		isDialog && selectedResults.length > 0 && !activeSearchId,
	);

	useEffect(() => {
		clearSearch();
		if (pathname === "/search") {
			clearSelection();
		}
		if (!isDialog) {
			setSelectionMode(false);
		}
	}, []);

	useEffect(() => {
		if (isDialog) {
			setSelectionMode(!showSelectedOnly);
		}
	}, [showSelectedOnly]);

	useEffect(() => {
		if (!activeSearchId) {
			setHasResults(false);
			setSearchResults([]);
			setResultsScrolled(false);

			if (isDialog) {
				setShowSelectedOnly(selectedResults.length > 0);
			}
		} else {
			if (activeSearch && activeSearch.id !== activeSearchId) {
				refetchActiveSearch();
				setSearchParams({
					...Object.fromEntries(searchParams),
					searchId: activeSearchId,
				});
			}
			if (isDialog) {
				setShowSelectedOnly(false);
			}
		}
	}, [activeSearchId, activeSearch, isDialog, selectedResults.length]);

	const performSearch = async (
		query: string,
		filters: SearchFiltersType | undefined,
	) => {
		if (!query || !activeSearchId) {
			setSearchResults([]);
			setHasResults(false);
			return;
		}

		try {
			const peopleEmails = people
				.filter((person) => filters?.peopleFilter?.includes(person.id!))
				.map((person) => person.email ?? "")
				.filter(Boolean);

			const searchFilters = {
				...filters,
				peopleFilter: peopleEmails,
				...(filters?.appFilter?.length ? { appFilter: filters.appFilter } : {}),
			};
			const response = await performNLSearch(
				query,
				searchFilters,
				activeSearchId,
			);

			setSearchResults(response.results);
			setHasResults(response.results.length > 0);
			updateSearch(true, response.results.length, filters);
		} catch (err) {
			updateSearch(false);
			console.error("Search error:", err);
			setHasResults(false);
		}
	};

	const clearSearch = () => {
		setHasResults(false);
		setSearchResults([]);
		clearActiveSearch();
		setActiveFilters({});
		setSelectionMode(false);
	};

	const handleToggleShowSelected = () => {
		setShowSelectedOnly(!showSelectedOnly);
	};

	const handleFilterChange: (
		filters: SearchFiltersType,
		onlySearch?: boolean,
	) => Promise<void> = async (
		filters: SearchFiltersType,
		onlySearch?: boolean,
	) => {
		let queryFilters: SearchFiltersType;
		if (onlySearch) {
			queryFilters = filters;
		} else {
			queryFilters = {
				...activeFilters,
				...(filters.appFilter ? { appFilter: filters.appFilter } : {}),
				...(filters.timestampFilter
					? { timestampFilter: filters.timestampFilter }
					: {}),
				...(filters.peopleFilter ? { peopleFilter: filters.peopleFilter } : {}),
			};
		}

		setActiveFilters(queryFilters);
		if (activeSearch?.query && activeSearchId) {
			await performSearch(activeSearch.query, queryFilters);
		}

		Analytics.trackEvent(ANALYTICS_EVENTS.SEARCH_FILTERED, {
			[ANALYTICS_PROPERTIES.SELECTED_FILTERS]: getAnalyticsFilters(
				queryFilters,
			) as any,
			[ANALYTICS_PROPERTIES.SEARCH_QUERY]: activeSearch?.query,
		});
	};

	const handleResultsScroll = (scrolledOut: boolean) => {
		setResultsScrolled(scrolledOut);
	};

	const handleSearch = async (query: string) => {
		if (isDialog && showSelectedOnly) {
			setShowSelectedOnly(false);
		}
		if (!query.trim()) {
			return;
		}

		const searchStartTime = Date.now();

		Analytics.trackEvent(ANALYTICS_EVENTS.SEARCH_STARTED, {
			[ANALYTICS_PROPERTIES.SEARCH_QUERY]: query,
			[ANALYTICS_PROPERTIES.SEARCH_TYPE]: isDialog ? "dialog" : "page",
		});

		const searchId = await createSearch(query);
		if (searchId) {
			try {
				await performSearch(query, activeFilters);

				Analytics.trackEvent(ANALYTICS_EVENTS.SEARCH_PERFORMED, {
					[ANALYTICS_PROPERTIES.SEARCH_QUERY]: query,
					[ANALYTICS_PROPERTIES.SEARCH_TYPE]: isDialog ? "dialog" : "page",
					[ANALYTICS_PROPERTIES.SEARCH_DURATION]: Date.now() - searchStartTime,
					[ANALYTICS_PROPERTIES.RESULTS_COUNT]: searchResults.length,
					[ANALYTICS_PROPERTIES.SELECTED_FILTERS]: getAnalyticsFilters(
						activeFilters,
					) as any,
				});

				if (searchResults.length === 0) {
					Analytics.trackEvent(ANALYTICS_EVENTS.SEARCH_NO_RESULTS, {
						[ANALYTICS_PROPERTIES.SEARCH_QUERY]: query,
					});
				}
			} catch (error) {
				Analytics.trackEvent(ANALYTICS_EVENTS.SEARCH_ERROR, {
					[ANALYTICS_PROPERTIES.SEARCH_QUERY]: query,
					[ANALYTICS_PROPERTIES.ERROR_TYPE]: "search_execution_error",
					[ANALYTICS_PROPERTIES.ERROR_MESSAGE]:
						error instanceof Error ? error.message : "Unknown error",
				});
			}
		}
	};

	const updateSearch = async (
		success: boolean,
		resultsCount?: number,
		filters?: SearchFiltersType,
	) => {
		if (!activeSearchId) return;
		const updateSearchData: Partial<SearchType> = {
			success: success,
			results_count: resultsCount,
			filters,
		};
		await updateSearchQuery(activeSearchId, updateSearchData);
	};

	const createSearch: (query: string) => Promise<UUID | null> = async (
		query: string,
	) => {
		try {
			const response = await createSearchQuery({
				query: query,
				source: "web",
				filters: activeFilters,
			});

			if (response?.id) {
				const params = new URLSearchParams(searchParams);
				const existingParams = Object.fromEntries(params.entries());

				setSearchParams({
					...existingParams,
					q: query,
					searchId: response.id,
				});

				refetchUserSearches();
				return response.id as UUID;
			}
			return null;
		} catch (error) {
			clearSearch();
			console.error("Error creating search:", error);
			return null;
		}
	};

	const filteredResults = React.useMemo(() => {
		if (!showSelectedOnly) return searchResults;
		return selectedResults;
	}, [searchResults, selectedResults, showSelectedOnly]);

	const isSearchActive = activeSearchId !== null;
	const showSearchHeader = !isSearchActive && !isSearchLoading && !isDialog;
	const showSearchResultsSummary =
		((hasResults || isSearchLoading) && isSearchActive) ||
		(showSelectedOnly && selectedResults.length > 0);

	return (
		<SearchContainer isdialog={isDialog.toString()}>
			{!isDialog && <SearchSidebar isFirstRender={isFirstRender} />}
			<SearchInnerContainer
				issearchactive={(isSearchActive || isSearchLoading).toString()}
				isdialog={isDialog.toString()}
			>
				{showSearchHeader && (
					<SearchHeader weight="medium">Search my data</SearchHeader>
				)}
				<StickyContainer
					resultsscrolled={resultsScrolled.toString()}
					issearchactive={isSearchActive.toString()}
					isdialog={isDialog.toString()}
				>
					<SearchBar
						clearSearch={clearSearch}
						isDialog={isDialog}
						handleSearch={handleSearch}
						isFirstRender={isFirstRender}
					/>
					{!isSearchActive && !isSearchLoading && !isDialog && (
						<AppFilterPreview onFilterChange={handleFilterChange} />
					)}
					{(isSearchActive || isSearchLoading || isDialog) && (
						<SearchFilters
							results={searchResults}
							onFilterChange={handleFilterChange}
							isFirstRender={isFirstRender}
							onToggleShowSelected={handleToggleShowSelected}
							isDialog={isDialog}
							showSelectedOnly={showSelectedOnly}
						/>
					)}
				</StickyContainer>
				{showSearchResultsSummary && (
					<SearchResultsSummary
						isDialog={isDialog}
						isSearchLoading={isSearchLoading}
						searchResults={filteredResults}
						resultsScrolled={resultsScrolled}
					/>
				)}
				<SearchResults
					isDialog={isDialog}
					isSearchLoading={isSearchLoading}
					searchResults={filteredResults}
					onResultsScroll={handleResultsScroll}
					showSelectedOnly={showSelectedOnly}
				/>
			</SearchInnerContainer>
		</SearchContainer>
	);
};

export default Search;
